:root {
  /* Red */
  --r1: #FFA9A9;
  --r2: #AB3939;
  --r3: #560000;
  --r3a: #56000077;
  /* Orange */
  --o1: #FFE2A9;
  --o2: #AB8539;
  --o3: #563A00;
  /* Yellow */
  --y1: #FEFEAA;
  --y2: #AAAA39;
  --y3: #555501;
  /* Green */
  --g1: #88CC88;
  --g2: #2E882E;
  --g3: #014401;
  /* Blue */
  --b1: #7887AB;
  --b2: #2E4372;
  --b3: #061539;
  --b3a: #06153977;
  /* Indigo */
  --i1: #877BB0;
  --i2: #413077;
  --i3: #14073D;
  /* Violet */
  --v1: #CD87AF;
  --v2: #8A2E62;
  --v3: #470028;

  /* Red */
  --r1: #FFA8A8;
  --r2: #FF1818;
  --r3: #CD0000;
  --r3a: #CD000077;
  /* Orange */
  --o1: #FFE5B1;
  --o2: #FFB218;
  --o3: #B07500;
  /* Yellow */
  --y1: #FFFFA8;
  --y2: #FFFF18;
  --y3: #CDCD00;
  /* Green */
  --g1: #98E698;
  --g2: #15DD15;
  --g3: #00A400;
  /* Blue */
  --b1: #90A0C6;
  --b2: #204AAB;
  --b3: #052776;
  --b3a: #05277677;
  /* Indigo */
  --i1: #A890C7;
  --i2: #5B1DAD;
  --i3: #350477;
  /* Violet */
  --v1: #C287C2;
  --v2: #A60FA6;
  --v3: #720072;

  --panelBG: linear-gradient( 11deg, var(--b3), black, var(--r3));
  --menuBG: radial-gradient( var( --r3a ), black, var( --b3a) );
  --listItemBG: linear-gradient( 11deg, var(--b3a), black, var(--r3));
  --listItemBG_Hover: linear-gradient( 11deg, var(--r3), black, var(--b3a));
  --gridAreaBG: radial-gradient( #fff7, #fff3, #fff0 );
}
@media ( orientation: portrait ){
  :root{
    --oF: 1;
  }

  @media ( min-width: 240px ){
    :root{
      --sF: 1;
    }
  }
  @media ( min-width: 480px ){
    :root{
      --sF: 1;
    }
  }
  @media ( min-width: 720px ){
    :root{
      --sF: 1.5;
    }
  }
  @media ( min-width: 1080px ){
    :root{
      --sF: 2.5;
    }
  }
  @media ( min-width: 2160px ){
    :root{
      --sF: 5;
    }
  }
}
@media ( orientation: landscape ){
  :root{
    --oF: 1;
  }

  @media ( min-height: 240px ){
    :root{
      --sF: 1;
    }
  }
  @media ( min-height: 480px ){
    :root{
      --sF: 1;
    }
  }
  @media ( min-height: 720px ){
    :root{
      --sF: 1.5;
    }
  }
  @media ( min-height: 1080px ){
    :root{
      --sF: 2.5;
    }
  }
  @media ( min-height: 2160px ){
    :root{
      --sF: 5;
    }
  }
}
@media ( min-resolution: 48dpi ){
  :root{
    --pD: 0.5;
  }
  html{
    font-size: calc(var(--oF) * var(--pD) * 2em)
  }
}
@media ( min-resolution: 96dpi ){
  :root{
    --pD: 1;
  }
  html{
    font-size: calc(var(--oF) * var(--pD) * 1em);
  }
}
@media ( min-resolution: 192dpi ){
  :root{
    --pD: 2;
  }
  html{
    font-size: calc(var(--oF) * var(--pD) * .5em);
  }
}
@media ( min-resolution: 288dpi ){
  :root{
    --pD: 3;
  }
  html{
    font-size: calc(var(--oF) * var(--pD) * .33em);
  }
}
@media ( min-resolution: 384dpi ){
  :root{
    --pD: 4;
  }
  html{
    font-size: calc(var(--oF) * var(--pD) * .25em);
  }
}

@media only screen {
  a{
    transition: all 0.2s;
  }

  body {
    margin: 0;
    font-family: 'Dosis', sans-serif;
  }
  body > *, body *{
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  li{
    list-style: none;
  }
  iframe::before{
    content: 'All your base are belong to us.';
    background-color: #fff;
    display: grid;
    height: 100%;
    width: 100%;
  }

  #root{
    position: fixed;
    z-index: 4;
    bottom: 0;
    left: 0;
    height: 100svh;
    width: 100svw;
    opacity: 1;
    display: grid;
    transition: opacity 2s .5s;
  }
  @media ( orientation: landscape ){
    #root{
      grid-template-areas:
        "head head head head head head head head head"
        "head head head head head head head head head"
        "space space space body body body body body body"
        "space space space body body body body body body"
        "space space space body body body body body body"
        "space space space body body body body body body"
        "menu menu menu body body body body body body"
        "menu menu menu body body body body body body"
        "menu menu menu body body body body body body";
      grid-template-columns: repeat( 9, 1fr );
      grid-template-rows: auto auto 1fr 1fr 1fr 1fr auto auto auto;
    }
    #root.no_gui{
      grid-template-areas:
        "lmarg  head  head  head  head  head  head  head  rmarg"
        "lmarg  body  body  body  body  body  body  body  rmarg"
        "lmarg  body  body  body  body  body  body  body  rmarg"
        "lmarg  body  body  body  body  body  body  body  rmarg"
        "lmarg  body  body  body  body  body  body  body  rmarg"
        "lmarg  body  body  body  body  body  body  body  rmarg"
        "lmarg  body  body  body  body  body  body  body  rmarg"
        "lmarg  body  body  body  body  body  body  body  rmarg"
        "lmarg  foot  foot  foot  foot  foot  foot  foot  rmarg" !important;
      grid-template-columns: repeat( 9, 1fr ) !important;
      grid-template-rows: repeat( 9, 1fr ) !important;
    }
  }
  @media ( orientation: portrait ){
    #root{
      grid-template-areas:
        "head head head head head head head head head"
        "head head head head head head head head head"
        "head head head head head head head head head"
        "body body body body body body body body body"
        "body body body body body body body body body"
        "body body body body body body body body body"
        "body body body body body body body body body"
        "body body body body body body body body body"
        "menu menu menu menu menu menu menu menu menu";
      grid-template-columns: repeat( 9, 1fr );
      grid-template-rows: auto auto auto 1fr 1fr 1fr 1fr 1fr auto;
    }
    #root.no_gui{
      grid-template-areas:
        "lmarg  head  head  head  head  head  head  head  rmarg"
        "lmarg  body  body  body  body  body  body  body  rmarg"
        "lmarg  body  body  body  body  body  body  body  rmarg"
        "lmarg  body  body  body  body  body  body  body  rmarg"
        "lmarg  body  body  body  body  body  body  body  rmarg"
        "lmarg  body  body  body  body  body  body  body  rmarg"
        "lmarg  body  body  body  body  body  body  body  rmarg"
        "lmarg  body  body  body  body  body  body  body  rmarg"
        "lmarg  foot  foot  foot  foot  foot  foot  foot  rmarg" !important;
      grid-template-columns: 1rem 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1rem !important;
      grid-template-rows: repeat( 9, 1fr ) !important;
    }
  }

  #sys_ve_canvas, #sys_ui_canvas, #page_ve_canvas, #page_ui_canvas {
    position: fixed;
    pointer-events: none;
  }
  #sys_ve_canvas {
    z-index: 1;
    transition: all 0.5s;
  }
  #sys_ve_canvas.blurry {
    filter: blur(.25em);
    width: calc(100vw + 2em) !important;
    height: calc(100vh + 2em) !important;
    margin: -1em !important;
  }
  #sys_ui_canvas {
    z-index: 4;
  }
  #sys_ui_canvas.onDisplay{
    perspective: none !important;
    z-index: 6 !important;
  }
  #page_ve_canvas {
    position: fixed;
    z-index: 2;
  }
  #page_ui_canvas {
    position: fixed;
    z-index: 3;
  }
  #page_ui_canvas.onDisplay{
    perspective: none !important;
    z-index: 5 !important;
  }

  /* BEGIN: PIP GUI Styling */
  .pip_continue, .pip_cancel, .pip_accept{
    border-radius: 50%;
    border: none;
    width: calc( var(--sF) * 3.5rem);
    height: calc( var(--sF) * 3.5rem);
    font-size: calc( var(--sF) * 1.25rem);
    pointer-events: all;
    cursor: pointer;
    transition: 0.2s;
    margin-right: calc( var(--sF) * 0.3rem);
  }
  .pip_continue:disabled, .pip_cancel:disabled, .pip_accept:disabled{
    cursor: not-allowed !important;
    background-color: #444 !important;
    color: #111 !important;
  }
  .pip_continue{
    background-color: var(--b2);
    color: var(--b1);
  }
  .pip_continue:hover{
    background-color: var(--b1);
    color: var(--b2);
  }
  .pip_continue:active{
    background-color: var(--b3);
  }
  .pip_cancel{
    background-color: var(--r2);
    color: var(--r1);
  }
  .pip_cancel:hover{
    background-color: var(--r1);
    color: var(--r2);
  }
  .pip_cancel:active{
    background-color: var(--r3);
  }
  .pip_accept{
    background-color: var(--g2);
    color: var(--g1);
  }
  .pip_accept:hover{
    background-color: var(--g1);
    color: var(--g2);
  }
  .pip_accept:active{
    background-color: var(--g3);
  }
  .pip_title{
    color: var(--o2);
    font-size: calc( var(--sF) * 2.25rem);
  }
  .pip_text{
    color: var(--b1);
    font-size: calc( var(--sF) * 1rem);
    width: 100%;
  }
  .pip_gui{
    display: grid;
    overflow: clip;
  }
  @media ( orientation: landscape ){
    .pip_gui{

    }
  }
  @media ( orientation: portrait ){
    .pip_gui{
      grid-column: 1 / -1;
      grid-row: 2 / -1;
    }
  }

  .body{
    grid-area: body;
  }
  .controls{
    grid-area: ctrl;
    min-width: calc( var(--sF) * 10rem );
  }
  .head{
    grid-area: head;
  }
  .foot{
    grid-area: foot;
  }
  .status{
    grid-area: status;
  }
  .controls > li{
    display: grid;
    grid-template-areas:
      "label label"
      "icon ctrl";
  }
  .controls > li:hover{
  }
  .controls > .image_button, .controls > .image_select, .controls > .image_toggle, .controls > .image_checkbox, .controls > .image_text{
    background: var(--listItemBG);
  }
  .controls > .image_button:hover, .controls > .image_select:hover, .controls > .image_toggle:hover, .controls > .image_checkbox:hover, .controls > .image_text:hover{
    background: var(--listItemBG_Hover);
  }
  .controls > .image_button{
    cursor: pointer;
    width: calc( var(--sF) * 4rem );
    height: calc( var(--sF) * 4rem );
    border: solid var(--i1);
    border-radius: 25%;
    margin: auto;
  }
  .controls > .image_button:nth-child( even ){
    float: left;
    clear: left;
    margin-left: calc( var(--sF) * 0.5rem );
  }
  .controls > .image_button:nth-child( odd ){
    float: right;
    clear: right;
    margin-right: calc( var(--sF) * 0.5rem );
  }
  .controls > .image_button label{
    white-space: nowrap;
    position: relative;
    bottom: calc( var(--sF) * 1rem );
    opacity: 0;
    transition: opacity 0.5s .2s;
  }
  .controls > .image_button:hover label{
    opacity: 1;
  }
  .controls > .image_select{
    margin-bottom: calc( var(--sF) * 1rem );
  }
  .controls > .image_select > img{
    grid-area: icon;
    height: calc( var(--sF) * 2rem );
  }
  .controls > .image_select > select{
    grid-area: ctrl;
    background: transparent;
    color: var(--y2);
    width: 100%;
    border: solid 1px;
    font-size: calc( var(--sF) * 0.75rem );
    cursor: pointer;
  }
  .controls > .image_select > label{
    grid-area: label;
    color: var(--o2);
    font-size: calc( var(--sF) * 1rem );
  }
  .controls > .image_toggle{
    margin-bottom: calc( var(--sF) * 1rem );
  }
  .controls > .image_toggle > img{
    grid-area: icon;
    height: calc( var(--sF) * 2rem );
  }
  .controls > .image_toggle > input[type="radio"]{
    grid-area: ctrl;
    background: transparent;
    color: var(--y2);
    width: 100%;
    border: solid 1px;
    font-size: calc( var(--sF) * 0.75rem );
    cursor: pointer;
  }
  .controls > .image_toggle > label{
    grid-area: label;
    color: var(--o2);
    font-size: calc( var(--sF) * 1rem );
  }
  .controls > .image_checkbox{
    margin-bottom: calc( var(--sF) * 1rem );
  }
  .controls > .image_checkbox > img{
    grid-area: icon;
    height: calc( var(--sF) * 2rem );
  }
  .controls > .image_checkbox > input[type="radio"]{
    grid-area: ctrl;
    background: transparent;
    color: var(--y2);
    width: 100%;
    border: solid 1px;
    font-size: calc( var(--sF) * 0.75rem );
    cursor: pointer;
  }
  .controls > .image_checkbox > label{
    grid-area: label;
    color: var(--o2);
    font-size: calc( var(--sF) * 1rem );
  }
  .controls > .image_text{
    background: var(--listItemBG);
    margin-bottom: calc( var(--sF) * 1rem );
  }
  .controls > .image_text:hover{
    background: var(--listItemBG_Hover);
  }
  .controls > .image_text > img{
    grid-area: icon;
    height: calc( var(--sF) * 2rem );
  }
  .controls > .image_text > input{
    grid-area: ctrl;
    background: transparent;
    color: var(--y2);
    width: 100%;
    border: solid 1px;
    font-size: calc( var(--sF) * 0.75rem );
    cursor: pointer;
  }
  .controls > .image_text > label{
    grid-area: label;
    color: var(--o2);
    font-size: calc( var(--sF) * 1rem );
  }

  /* BEGIN: PIP Chat Styling */
  /* PIP Chat screens are forms styled as a dialogue between the system and the user.
     This style allows for decisions to be made and input to be handled. */
  .pip_gui.pip_chat{
    --s: 7rem; /* control the size */
    background:
      conic-gradient(at 80% 80%,var(--i3) 75%,#0000 0),
      linear-gradient(135deg,var(--i3) calc(40%/3),
        #0000 0 calc(200%/3),var(--i3) 0),
      conic-gradient(from 45deg at calc(160%/3) calc(80%/3),
        #0008 135deg,#0000 0 225deg,#0004 0)
      var(--i3); /* cube color */
    background-size: var(--s) var(--s);
    border: solid 1px var(--o3);
    grid-area: body;
    grid-template-columns: 1rem 1fr 1fr 1fr 1rem;
    grid-template-rows: repeat( 25, auto );
    overflow-y: auto;
    border-radius: 0rem 5rem 0rem 5rem;
    place-items: baseline;
  }

  .pip_gui.pip_chat .ui_side{
    grid-column: 2 / 4;
    border-radius: 0px 4rem 4rem 4rem;
    margin-left: 0;
    margin-right: auto;
    display: flex;
    width: calc( 100% - 2rem );
    padding: 1rem;
  }
  .pip_gui.pip_chat .user_side{
    text-align: right;
    grid-column-end: 5;
    border-radius: 4rem 4rem 0rem 4rem;
    margin-right: 0;
    margin-left: auto;
    width: calc( 100% - 2rem );
    padding: 1rem;
    background: linear-gradient( 270deg, var(--b2), #fff1 ) !important;
  }
  .pip_gui.pip_chat .pip_title{
    grid-column: 1 / -1;
    text-align: center;
    place-self: center;
  }
  .pip_gui.pip_chat .pip_text{
    border: none;
    background: linear-gradient( 90deg, var(--b2), #fff1 );
  }

  @media ( orientation: landscape ){
    .pip_gui.pip_chat .pip_text{

    }
  }
  @media ( orientation: portrait ){
    .pip_gui.pip_chat .pip_text{

    }
  }
  .pip_gui.pip_chat .pip_button{
    width: fit-content;
  }
  .pip_gui.pip_chat .pip_toggle{
    grid-row: 3;
    grid-column: 3;
    width: 2rem;
    height: 1rem;
  }
  .pip_gui.pip_chat .pip_continue{
  }
  .pip_gui.pip_chat .pip_cancel{
  }
  .pip_gui.pip_chat .pip_accept{
  }


  /* BEGIN: PIP Splash Styling */
  /* PIP Splash screens are autonomous heads up displays which appear and disappear on their own.
     This style is useful for displaying the status of running processes, which don't require acknowlegement. */
 .pip_gui.pip_splash{
    display: grid;
    border: solid 1px var(--o3);
    grid-area: body;
    --s: 3rem; /* control the size */

    --_s: calc(2*var(--s)) calc(2*var(--s));
    --_g: var(--_s) conic-gradient(at 40% 40%,#0000 75%,var(--b3) 0);
    --_p: var(--_s) conic-gradient(at 20% 20%,#0000 75%,var(--b3) 0);
    background:
      calc( .9*var(--s)) calc( .9*var(--s))/var(--_p),
      calc(-.1*var(--s)) calc(-.1*var(--s))/var(--_p),
      calc( .7*var(--s)) calc( .7*var(--s))/var(--_g),
      calc(-.3*var(--s)) calc(-.3*var(--s))/var(--_g),
      conic-gradient(from 90deg at 20% 20%,var(--i3) 25%,var(--i3) 0)
       0 0/var(--s) var(--s);
    animation: splash 3s infinite;

  }
  @keyframes splash {
    0% {
     background-position:
      calc( .9*var(--s)) calc( .9*var(--s)),
      calc(-.1*var(--s)) calc(-.1*var(--s)),
      calc( .7*var(--s)) calc( .7*var(--s)),
      calc(-.3*var(--s)) calc(-.3*var(--s)),0 0;
    }
    25% {
     background-position:
      calc(1.9*var(--s)) calc( .9*var(--s)),
      calc(-1.1*var(--s)) calc(-.1*var(--s)),
      calc(1.7*var(--s)) calc( .7*var(--s)),
      calc(-1.3*var(--s)) calc(-.3*var(--s)),0 0;
    }
    50% {
     background-position:
      calc(1.9*var(--s)) calc(-.1*var(--s)),
      calc(-1.1*var(--s)) calc( .9*var(--s)),
      calc(1.7*var(--s)) calc(-.3*var(--s)),
      calc(-1.3*var(--s)) calc( .7*var(--s)),0 0;
    }
    75% {
     background-position:
      calc(2.9*var(--s)) calc(-.1*var(--s)),
      calc(-2.1*var(--s)) calc( .9*var(--s)),
      calc(2.7*var(--s)) calc(-.3*var(--s)),
      calc(-2.3*var(--s)) calc( .7*var(--s)),0 0;
    }
    100% {
     background-position:
      calc(2.9*var(--s)) calc(-1.1*var(--s)),
      calc(-2.1*var(--s)) calc(1.9*var(--s)),
      calc(2.7*var(--s)) calc(-1.3*var(--s)),
      calc(-2.3*var(--s)) calc(1.7*var(--s)),0 0;
    }
  }
  .pip_gui.pip_splash .pip_title{
    text-align: center;
  }
  .pip_gui.pip_splash .pip_text{
    border: none;
    background: linear-gradient( 90deg, #ffffff42, #ffffff00 );
    text-align: center;
  }
  .pip_gui.pip_splash .pip_button{
    width: fit-content;
  }
  .pip_gui.pip_splash .pip_toggle{
    grid-row: 3;
    grid-column: 3;
    width: 4rem;
    height: 4rem;
  }
  .pip_gui.pip_splash .pip_continue{

  }
  .pip_gui.pip_splash .pip_cancel{

  }
  .pip_gui.pip_splash .pip_accept{

  }

  /* BEGIN: PIP Menu Styling */
  /* PIP Menu screens are form panels which require user interaction to determine where to take the user next.
     This style allows for the system to respond more complexly to the user requests. */
  .pip_gui.pip_menu{
    grid-area: body;
    grid-column: 1 / -1;
    z-index: 1;
    grid-template-areas:
      "head head head head ctrl"
      "body body body body ctrl"
      "body body body body ctrl"
      "body body body body ctrl"
      "body body body body ctrl";
    grid-template-columns: repeat( 5, 1fr );
    grid-template-rows: auto 1fr 1fr 1fr 1fr;
    border-radius: 10vh 0vh 0 10vh;

    --s: 80px; /* the size */
    --c: var(--i3);

    --_s: calc(2*var(--s)) calc(2*var(--s));
    --_g: 35.36% 35.36% at;
    --_c: #0000 66%,var(--i2) 68% 70%,#0000 72%;
    background:
      radial-gradient(var(--_g) 100% 25%,var(--_c)) var(--s) var(--s)/var(--_s),
      radial-gradient(var(--_g) 0    75%,var(--_c)) var(--s) var(--s)/var(--_s),
      radial-gradient(var(--_g) 100% 25%,var(--_c)) 0 0/var(--_s),
      radial-gradient(var(--_g) 0    75%,var(--_c)) 0 0/var(--_s),
      repeating-conic-gradient(var(--c) 0 25%,#0000 0 50%) 0 0/var(--_s),
      radial-gradient(var(--_c)) 0 calc(var(--s)/2)/var(--s) var(--s)
      var(--c);

    border: solid 1px var(--o3);
  }
  .pip_gui.pip_menu.loading{
    --s: 42rem;
  }
  .pip_gui.pip_menu .pip_title{
    background: linear-gradient( 90deg, #0007, var(--b3) );
    margin: 0;
    padding: 1rem;
    text-align: right;
  }
  .pip_gui.pip_menu .controls{
    background: linear-gradient( 0deg, #0007, var(--b3) calc( 100% - 5rem ) );
  }
  .pip_gui.pip_menu .pip_text{
    border: none;
    background: linear-gradient( 90deg, #ffffff42, #ffffff00 );
  }
  .pip_gui.pip_menu .pip_button{
    width: fit-content;
  }
  .pip_gui.pip_menu .pip_toggle{
    grid-row: 3;
    grid-column: 3;
    width: 4rem;
    height: 4rem;
  }
  .pip_gui.pip_menu .pip_continue{

  }
  .pip_gui.pip_menu .pip_cancel{

  }
  .pip_gui.pip_menu .pip_accept{

  }

  /* BEGIN: PIP Post Styling */
  /* PIP Post screens are notification pages which require acknowlegement before moving on.
     This style allows for the system to post information which the user may wish to examine further before continuing on. */
  .pip_gui.pip_post{
    grid-area: body;
    grid-template-areas:
      "head  head  head  head head"
      "ctrl body body body body"
      "ctrl body body body body"
      "status status status status status"
      "foot  foot  foot  foot foot";
    grid-template-columns: repeat( 5, 1fr );
    grid-template-rows: auto 2fr 2fr 1fr auto;
    border: solid 1px var(--o3);
    border-radius: 0 10vh 0 10vh;
    /* Background Pattern */
    --s: 100px; /* control the size */
    --t: transparent;
    --_g:
       var(--b3) 6%  14%,var(--t) 16% 24%,var(--i3) 26% 34%,var(--t) 36% 44%,
       var(--b3) 46% 54%,var(--t) 56% 64%,var(--i3) 66% 74%,var(--t) 76% 84%,var(--b3) 86% 94%;
    background:
      radial-gradient(100% 100% at 100% 0,var(--t) 4%,var(--_g),#0008 96%,#0000),
      radial-gradient(100% 100% at 0 100%,#0000, #0008 4%,var(--_g),var(--t) 96%)
      var(--t);
    background-size: var(--s) var(--s);
  }
  .pip_gui.pip_post .pip_title{
    background: linear-gradient( 90deg, var(--i2), #0007 );
    margin: 0;
    grid-area: head;
  }
  .pip_gui.pip_post .pip_text{
    border: none;
    color: var(--b1);
    background: linear-gradient( 90deg, var(--i2), #0007 );

  }
  .pip_gui.pip_post .controls{
    grid-area: ctrl;
  }
  .pip_gui.pip_post .pip_button{
  }
  .pip_gui.pip_post .pip_toggle{
  }
  .pip_gui.pip_post .pip_continue{

  }
  .pip_gui.pip_post .pip_cancel{
  }
  .pip_gui.pip_post .pip_accept{
  }


  .pip_post .head, .pip_post .controls, .pip_post .body, .pip_post .status, .pip_post .foot{
    position: relative;
    height: 100%; width: 100%;
    background: var(--gridAreaBG);
  }
  .pip_post .head{
    grid-area: head;
  }
  .pip_post .controls{
    grid-area: ctrl;
  }
  .pip_post .body{
    grid-area: body;
    display: flex;
  }
  .pip_post .status{
    grid-area: status;
    overflow-y: auto;
  }
  .pip_post .foot{
    grid-area: foot;
    display: grid;
    grid-template-columns: repeat( 10, 1fr );
    grid-auto-rows: 1fr;
  }


  /* BEGIN: PIP Import Styling */
  /* PIP Import screens are externally-sourced
     This style allows for the system to post information which the user may wish to examine further before continuing on. */
  .pip_gui.pip_import{
    grid-area: body;
    margin-right: 2rem;
    display: grid;
    grid-template-rows: auto 5rem auto 5rem;
  }
  .pip_gui.pip_import > iframe{
    border-radius: 0 10vh 0 10vh;
    grid-row: 2 / -1;
    height: calc( 100vh - 5rem);
    width: 100%;
  }

  .pip_gui.pip_import .pip_title{

  }
  .pip_gui.pip_import .pip_text{
    border: none;
    background: linear-gradient( 90deg, #ffffff42, #ffffff00 );
    text-align: center;
  }
  .pip_gui.pip_import .pip_button{
    width: fit-content;
  }
  .pip_gui.pip_import .pip_toggle{
    grid-row: 3;
    grid-column: 3;
    width: 4rem;
    height: 4rem;
  }
  .pip_gui.pip_import .pip_continue{

  }
  .pip_gui.pip_import .pip_cancel{

  }
  .pip_gui.pip_import .pip_accept{

  }
}
